'use client';

import { Toaster } from '@/components/ui/sonner';
import { useLayoutEffect, type ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEditorStore } from '@/store/editorStore';

const queryClient = new QueryClient();

export default function Providers({ children }: { children: ReactNode }) {
  const { setQueryClient } = useEditorStore();

  useLayoutEffect(() => {
    setQueryClient(queryClient);
  }, [queryClient]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <Toaster invert theme="dark" position="bottom-center" />
    </QueryClientProvider>
  );
}

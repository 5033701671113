import { create } from 'zustand';

interface EditorState {
  isPanelOpen: boolean;
  documentId: string;
  isLibraryOpen: false | 'penno' | 'library' | 'manual';
  editor: any;
  isSettingsOpen: boolean;
  queryClient: any;
  isChatOpen: boolean | string;
  isProcessing: boolean;
  isKnowledgeOpen: boolean;
  aiStartPrompt: boolean;
  isGlobalSettingsOpen: false | string;

  setPanelOpen: (isOpen: boolean) => void;
  setDocumentId: (id: string) => void;
  setLibraryOpen: (isOpen: false | 'penno' | 'library' | 'manual') => void;
  setEditor: (editor: any) => void;
  setSettingsOpen: (isOpen: boolean) => void;
  setQueryClient: (client: any) => void;
  setChatOpen: (isOpen: boolean | string) => void;
  setIsProcessing: (isProcessing: boolean) => void;
  setKnowledgeOpen: (isKnowledgeOpen: boolean) => void;
  setAiStartPrompt: (aiStartPrompt: boolean) => void;
  setGlobalSettingsOpen: (isGlobalSettingsOpen: false | string) => void;
}

export const useEditorStore = create<EditorState>(set => ({
  isPanelOpen: true,
  documentId: '',
  isLibraryOpen: false,
  editor: 'null',
  isSettingsOpen: false,
  queryClient: null,
  isChatOpen: false,
  isProcessing: false,
  isKnowledgeOpen: false,
  aiStartPrompt: false,
  isGlobalSettingsOpen: false,

  setPanelOpen: isOpen => set(state => ({ isPanelOpen: isOpen })),
  setDocumentId: id => set(state => ({ documentId: id })),
  setLibraryOpen: isOpen => set(state => ({ isLibraryOpen: isOpen })),
  setEditor: editor => set(state => ({ editor })),
  setSettingsOpen: isOpen => set(state => ({ isSettingsOpen: isOpen })),
  setQueryClient: client => set(state => ({ queryClient: client })),
  setChatOpen: isOpen => set(state => ({ isChatOpen: isOpen })),
  setIsProcessing: isProcessing => set(state => ({ isProcessing: isProcessing })),
  setKnowledgeOpen: isKnowledgeOpen => set(state => ({ isKnowledgeOpen: isKnowledgeOpen })),
  setAiStartPrompt: aiStartPrompt => set(state => ({ aiStartPrompt: aiStartPrompt })),
  setGlobalSettingsOpen: isGlobalSettingsOpen => set(state => ({ isGlobalSettingsOpen: isGlobalSettingsOpen })),
}));
